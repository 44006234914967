import React, { useState } from "react";
import "./banner.scss"

import arrowsIcon from "../../images/arrows.svg";
import { Stepper } from "../stepper/stepper";
import PartnerBanner from "../partner-banner/PartnerBanner";
import { HOME_STEPS, PAGES_DATA } from "../../utils/constants";
import { Button } from "../button/button";
import { Link } from "gatsby";
import handleViewport from "react-in-viewport";

const BannerRaw = ({ page, pageData, isBottomAligned, inViewport, forwardedRef }) => {
  const data = pageData ?? PAGES_DATA.find(data => data.id === page);
  const downLink = data.id === "home" ? HOME_STEPS[1].url : "layout-content";

  const [showTitle, setShowTitle] = useState(false);
  if (inViewport && !showTitle) {
    setShowTitle(true);
  }

  const getLinkURL = (link) => {
    if (link.url.indexOf("http") === 0) {
      return link.url;
    } else {
      return `#${link.url}`;
    }
  };

  return (
    <div className={`my-banner ${data.items ? "my-banner--bottom" : ""}`} id="top" ref={forwardedRef}>
      {page === "home" && <Stepper steps={HOME_STEPS} currentPage="/" selected={0} isFirst={true} />}
      <picture>
        <source media="(min-width:480px)" srcSet={data.largeBannerWP} type="image/webp" />
        <source media="(min-width:480px)" srcSet={data.largeBanner} />
        <source srcSet={data.bannerWP} type="image/webp" />
        <img className={`my-banner__img ${isBottomAligned ? "my-banner__img--bottom" : ""}`} src={data.bannerImage} alt="Boat banner" />
      </picture>
      <div className={`my-banner-content ${data.items ? "my-banner-content--bottom" : ""} ${data.hideArrows ? "my-banner-content--center" : ""}`}>
        {!data.hideArrows && <div className="my-banner__hidden"> </div>}
        {!data.subtitle &&
          <h1 className={`my-banner__title ${showTitle ? "my-banner__title--show" : ""}`}>{data.title}</h1>}
        {data.subtitle && <div className={`my-banner-main ${showTitle ? "my-banner-main--show" : ""}`}>
          <h1>{data.title}</h1>
          <h2>{data.subtitle}</h2>
          <Button text={data.btnText ?? "Learn more"} url={data.url} />
        </div>}
        {data.items && <div className={`my-banner-items-container ${showTitle ? "my-banner-items-container--show" : ""}`}>
          <div className="my-banner-items__border" />
          <div className="my-banner-items">
            {data.items.map(item => {
              return (
                <Link key={item.name} className="my-banner-item" to={getLinkURL(item)}>
                  <img className="my-banner-item__icon" src={item.icon} alt={item.name} />
                  <span>{item.shortName ?? item.name}</span>
                </Link>
              );
            })}
          </div>
        </div>}
        {!data.hideArrows && <Link className={`my-banner__arrows ${data.items ? "my-banner__arrows--extra" : ""}`} to={`#${downLink}`}>
          <img src={arrowsIcon} alt="Go down" />
        </Link>}
        {data.id === "home" && <PartnerBanner />}
      </div>
    </div>
  );
}

export const Banner = handleViewport(BannerRaw, { rootMargin: "0px" }, { disconnectOnLeave: true })
