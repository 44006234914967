import React from 'react'

import "./partner-banner.scss";
import vantonLogo from "../../images/vanton-logo.svg";
import { Link } from 'gatsby';

const PartnerBanner = () => {
  return (
    <div className='partner-banner__wrapper'>
      <div className='partner-banner__sections-wrapper'>
        <div className='partner-banner__info-section'>
          <p className='partner-banner__title'>Proud partners and official dealer for</p>
          <div className='partner-banner__logo'>
            <img src={vantonLogo} alt='Vanton logo' />
          </div>
        </div>
        <div className='partner-banner__link-section'>
          <Link className='partner-banner__link' to='/vanton'>Check it out &gt;</Link>
        </div>
      </div>
    </div>
  )
}

export default PartnerBanner